import React from 'react';
import './css/SurveyProgress.css';
import { DataGrid } from '@mui/x-data-grid';
import * as ft from '../utils/fetch';

export default function SurveyProgress() {

    // State Variables
    const [townData, setTownData] = React.useState([]);
    const [loadProgress, setLoadProgress] = React.useState({ done: 0, total: 0 });

    // String percentage Sort Comparator
    const sortPercentageString = (v1, v2) => {
        if(v1 === '100%') { return 1 }
        else if(v2 === '100%') { return -1 }
        else { return v1.localeCompare(v2) }
    }

    // Table Columns
    const columns = [
        { field: 'town', headerName: 'Town', width: 500 },
        { field: 'poles', headerName: 'Total Poles', width: 250 },
        { field: 'done_surveys', headerName: 'Completed Surveys', width: 250 },
        { 
            field: 'progress', 
            headerName: 'Progress', 
            width: 125, 
            type: 'string',
            sortComparator: sortPercentageString,
        },
    ];

    // Load Town Data
    const load_progress_data = async () => {

        // Grab Town List
        let town_list = await ft.fetch_get('/get/surveypole/towns');
        if(town_list.data) { town_list = town_list.data }
        else { return }

        // Set Progress Cap
        await setLoadProgress(obj => { return { ...obj, total: town_list.length } });

        // Declare temp array for loading
        let data = [];

        // Iterate over Towns
        for(let i = 0; i < town_list.length; i++) {

            // Grab town Poles
            let pole_data = await ft.fetch_get(`/get/remaining/surveypoles/${town_list[i]}`);

            // Calculate Totals
            let total_count = pole_data.total_poles;
            let done_count = pole_data.done_poles;
            let progress = `${Math.round((done_count / total_count) * 100)}%`;

            // Add leading 0 to single digit numbers
            if(progress.length < 3) { progress = `0${progress}` }

            // Edit Row Array to add town data
            data.push({ 
                id: i, 
                town: town_list[i], 
                poles: total_count,
                done_surveys: done_count,
                progress: progress
            });

            // Town Progress Increment
            await setLoadProgress(obj => { return { ...obj, done: (obj.done + 1) } });
        }

        setTownData(data);
    }

    // React Effects
    React.useEffect(() => { load_progress_data() }, []);

    return (
        <div className="body">
            {townData.length === 0 ?
                <h1>{`Loading... ${loadProgress.done}/${loadProgress.total}`}</h1>
                :
                <DataGrid
                    sx={{ margin: '20px' }}
                    rows={townData}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 30 },
                        },
                    }}
                    pageSizeOptions={[10, 30, 100]}
                    disableRowSelectionOnClick
                />
            }
        </div>
    );
}