import React from 'react';
import './css/SurveyTabs.css';

export default function SurveyTabs({ currentTab, set_survey_tab }) {
    return (
        <div className='tab-container'>
            <div className={ currentTab === 1 ? 'tab tab-active' : 'tab' } onClick={ () => set_survey_tab(1) }>
                <span className="tab-text">Survey Employees</span>
            </div>
            <div className={ currentTab === 2 ? 'tab tab-active' : 'tab' } onClick={ () => set_survey_tab(2) }>
                <span className="tab-text">Reports</span>
            </div>
            <div className={ currentTab === 3 ? 'tab tab-active' : 'tab' } onClick={ () => set_survey_tab(3) }>
                <span className="tab-text">Progress</span>
            </div>
            <div className={ currentTab === 4 ? 'tab tab-active' : 'tab' } onClick={ () => set_survey_tab(4) }>
                <span className="tab-text">Analytics</span>
            </div>
        </div>
    )
}