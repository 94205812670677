import React from 'react';
import './css/SurveyAnalytics.css';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as ft from '../utils/fetch';

export default function SurveyAnalytics() {

    // State Variables
    const [emp_id, setEmpID] = React.useState(null);
    const [emp_list, setEmpList] = React.useState([]);
    const [reportData, setReportData] = React.useState({});
    const [dateData, setDateData] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    // Load Data Method
    const load_data = async (eid, start, end) => {

        // Initialize Data
        let end_date = new Date(end);
        end_date.setDate(end_date.getDate() + 1);
        let temp_date = new Date(start);
        let survey_data = {};
        let date_data = [];
        let report_data = [];

        // collect reports from data
        while(
            temp_date.getFullYear() !== end_date.getFullYear() ||
            temp_date.getMonth() !== end_date.getMonth() ||
            temp_date.getDate() !== end_date.getDate() 
        ) {

            // Grab Reports
            let reports;
            if(eid === null) {
                reports = await ft.fetch_get('/get/polesurveys?date=' + temp_date.toISOString().split('T')[0]);
            } else {
                reports = await ft.fetch_get(`/get/polesurveys?eid=${eid}&date=${temp_date.toISOString().split('T')[0]}`);
            }
            
            // If add reports if this day has any
            if(reports.data.length > 0) { survey_data[temp_date.toISOString()] = reports }

            // Increment by 1 day
            temp_date.setDate(temp_date.getDate() + 1);
        }

        // Set States for Graph
        for(const [key, value] of Object.entries(survey_data)) {

            // Create temp date object
            let temp_date = new Date(key);

            // Add date string to list
            date_data.push(`${temp_date.getMonth() + 1}/${temp_date.getDate()}`);

            // Push the count of surveys for that day
            report_data.push(value.data.length);
        }

        // Set State Variables
        setReportData(report_data);
        setDateData(date_data);
    }


    // Load Employee Method
    const load_emps = async () => {
        let testers = (await ft.fetch_get('/get/surveyemployees')).data;
        let emps = (await ft.fetch_get('/get/employees')).data;
        emps = emps.filter((e) => testers.filter((t) => t.employee_id === e._id.toString()).length > 0);
        let emp_array = emps.map((e) => { return { label: `${e.first_name} ${e.last_name}`, value: e._id }})
        emp_array.unshift({ label: 'All Employees', value: null });
        setEmpList(emp_array);
    }

    // Load data when date Changes
    React.useEffect(() => {
        if(startDate !== null && endDate !== null) { load_data(emp_id, startDate, endDate); }
    }, [startDate, endDate, emp_id]);
    
    // Load Employee List
    React.useEffect(() => { load_emps() }, []);

    return (
        <div className="body">
            <div className="quick-row">
                {/*<Autocomplete
                    id="combo-box-demo"
                    options={emp_list}
                    onChange={ (e, v) => setEmpID(v.value) }
                    sx={{ marginLeft: '10px', marginTop: '10px', marginRight: '10px', marginBottom: '10px', flex: 1 }}
                    renderInput={(params) => <TextField 
                        {...params} 
                        label="Employee" 
                    />}
                />*/}
                <LocalizationProvider dateAdapter={ AdapterDateFns }>
                    <DemoContainer components={ ['DatePicker'] } sx={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}>
                        <DatePicker
                            label="Start Report"
                            value={ startDate }
                            onChange={ (v) => setStartDate(v) }
                        />
                    </DemoContainer>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={ AdapterDateFns }>
                    <DemoContainer components={ ['DatePicker'] } sx={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}>
                        <DatePicker
                            label="End Report"
                            value={ endDate }
                            onChange={ (v) => setEndDate(v) }
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </div>
            {(dateData !== null && reportData !== null) &&
                <BarChart
                    xAxis={[{
                        id: 'barCategories',
                        data: dateData.length > 0 ? dateData : [''],
                        scaleType: 'band',
                    }]}
                    series={[
                        { data: reportData.length > 0 ? reportData : [0], stack: 'main', label: 'Pole Surveys', color: 'blue' },
                    ]}
                    height={400}
                />
            }
        </div>
    );
}