import React from "react";
import './css/SelectPoleModal.css';
import Modal from '@mui/material/Modal';
import { FaRegWindowClose, FaSave } from 'react-icons/fa';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import * as ft from '../utils/fetch';

// Google API Load Script
const loadScript = {
    googleMapsApiKey: 'AIzaSyCaZY4QqwABidvYdwYsDqVCqeUI7xRYmgI',
    libraries: ['marker']
};

// Google Map Options
const mapOptions = {
    disableDefaultUI: true,
    clickableIcons: false,
    fullscreenControl: true,
    mapId: '2b5188176d69936b',
};

export default function SelectPoleModal({ packet_id, isOpen, setPacketID, loadPacketData }) {

    // State Variables
    const [packet, setPacket] = React.useState({ _id: 'none' });
    const [lastPole, setLastPole] = React.useState(null)
    const [packetPoleList, setPacketPoleList] = React.useState([]);
    const [originalPacketPoleList, setOriginalPacketPoleList] = React.useState([]);
    const [poleList, setPoleList] = React.useState([]);
    const [otherPacketsPoleList, setOtherPacketsPoleList] = React.useState([]);
    const [center, setCenter] = React.useState({ lat: 42, lng: -71 });
    const [mapKey, setMapKey] = React.useState(0);
    const [mapRef, setMap] = React.useState(null);
    const [bounds, setBounds] = React.useState(null);

    // Load Google API Script
    const { isLoaded } = useLoadScript(loadScript);

    // Load Packet method
    const load_packet = async () => {

        // Attempt to load packet
        try {
            let result = await ft.fetch_get(`/get/polepacket?id=${packet_id}`);
    
            // Save data if successfull
            if(result.data) { 
                setPacket(result.data[0])
                
                // Add poles list to packer list
                if(result.data[0].poles) { 
                    setPacketPoleList(result.data[0].poles);
                    setOriginalPacketPoleList(result.data[0].poles);
                }

                // Load list of other packet poles
                let other_packets = await ft.fetch_get(`/get/polepacket?employee_id=${result.data[0].employee_id}`);

                if(other_packets.data) {

                    other_packets = other_packets.data.filter(p => p._id !== packet_id);

                    let other_pole_list = [];

                    for(let i = 0; i < other_packets.length; i += 1) {
                        for(let j = 0; j < other_packets[i].poles.length; j += 1) {
                            other_pole_list.push(other_packets[i].poles[j]);
                        }
                    }

                    setOtherPacketsPoleList(other_pole_list);
                }
            }

        // Log critical errors
        } catch(e) { console.warn(e) }
    }

    // Load Pole Method
    const load_pole_markers = async () => {
        
        // Try to load the poles from our packets town
        try {
            let result = await ft.fetch_get(`/get/poles?town=${packet.town}`);
            
            // Apply new pole list to state
            if(result.data) { setPoleList(result.data) }

        // Catch Critical Errors
        } catch(e) { console.warn(e) }
    }

    // Save Pole Packet
    const save_packet = async () => {

        // Prompt to ensure they want to save
        if(window.confirm('Are you sure you want to save this packet?')) {

            // Construct the new object
            let new_obj = { ...packet };
            new_obj.poles = packetPoleList;

            // Unset old list Pole Status
            try {
                let result = await ft.fetch_post('/remove/pole/status/many', { poles: originalPacketPoleList })
                if(result.result !== 'success') { return alert('ERROR: Failed to un-set old poles.') }
                console.log(result);
            } catch(e) { console.warn(e) }

            // Set new Pole Status
            try {
                let result = await ft.fetch_post('/update/pole/status/many', { poles: packetPoleList, status: 1 })
                if(result.result !== 'success') { return alert('ERROR: Failed to set new pole status.') }
                console.log(result);
            } catch(e) { console.warn(e) }

            // Upload object
            try {
                let result = await ft.fetch_post('/update/polepacket', new_obj);
                if(result.result !== 'success') { return alert('ERROR: Failed to upload new packet information.') }
                console.log(result);
            } catch(e) { console.warn(e) }

            // Reload Packet Data
            loadPacketData();

            // Exit Modal
            setPacketID('none');
        }
    }

    // Calculate Center of Poles
    const calc_center = () => {
        let lat = 0, lng = 0;
        poleList.forEach( pole => { lat += parseFloat(pole.latitude); lng += parseFloat(pole.longitude); });
        return { lat: lat / poleList.length, lng: lng / poleList.length };
    }

    // Map Methods
    const map_loaded = (map) => { setMap(map) }
    const map_unloaded = (map) => { setMap(null) }
    const update_bounds = () => { if(mapRef !== null) {
        setBounds({ 
            sw: { 
                lat: mapRef.getBounds().getSouthWest().lat(),
                lng: mapRef.getBounds().getSouthWest().lng()
            }, 
            ne: {
                lat: mapRef.getBounds().getNorthEast().lat(),
                lng: mapRef.getBounds().getNorthEast().lng()
            }
        })
    }}

    // React Effects
    React.useEffect(() => { if(packet_id !== 'none' && isOpen) { load_packet(); } }, [isOpen]);
    React.useEffect(() => { if(packet._id !== 'none') { setPacketPoleList(packet.poles); load_pole_markers(); } }, [packet]);
    React.useEffect(() => { if(poleList.length > 0) { setCenter(calc_center()); } }, [poleList]);

    // Render Component
    return (
        <Modal
            open={isOpen}
            onClose={() => setPacketID('none')}
        >
            <div className="packet-pole-select-modal-container">
                <div className="select-pole-modal-control-container">
                    <div className="select-pole-modal-control-section">
                        <div className="select-pole-modal-control-section-stack">
                        <span className="select-pole-modal-pole-count-text">
                            {`${packet.packet_name} | Pole Count: ${(packetPoleList.length)}`}
                        </span>
                        <span className="select-pole-modal-pole-current-text">
                            {lastPole !== null && `(${lastPole.pole_number}, ${lastPole.gis_id})`}
                        </span>
                        </div>
                    </div>
                    <div className="select-pole-modal-control-section-right">
                        <div className="select-pole-modal-save-btn">
                            <FaSave size={40} title="Save Pole List"
                                className="modal-save-btn"
                                onClick={() => save_packet()}
                            />
                        </div>
                        <div className="select-pole-modal-close-btn">
                            <FaRegWindowClose size={40} title="Close Window"
                                className="modal-close-btn"
                                onClick={() => setPacketID('none')}
                            />
                        </div>
                    </div>
                </div>
                <div className="pole-packet-select-map-container">
                    {isOpen ? (!isLoaded ? 'Loading...' :
                        <GoogleMap
                            key={mapKey}
                            zoom={18}
                            center={center}
                            mapContainerClassName='pole-packet-select-map'
                            options={mapOptions}
                            onLoad={map_loaded}
                            onUnmount={map_unloaded}
                            onDragEnd={update_bounds}
                            onZoomChanged={update_bounds}
                        >
                            {((mapRef !== null && bounds !== null) ? poleList.map( pole => {
                                if(
                                    pole.latitude > bounds.sw.lat && 
                                    pole.latitude < bounds.ne.lat && 
                                    pole.longitude > bounds.sw.lng && 
                                    pole.longitude < bounds.ne.lng
                                ) {
                                    if(pole.status && !originalPacketPoleList.includes(pole._id)) {
                                        if(pole.status === 1) {
                                            if(otherPacketsPoleList.includes(pole._id)) {
                                                return <Marker 
                                                    position={{ lat: pole.latitude, lng: pole.longitude }} 
                                                    icon={{
                                                        url: 'https://easapi.enviroarborsolutions.com/svgs/utility_pole_orange.svg',
                                                        scaledSize: { width: 32, height: 32 }
                                                    }}
                                                    onClick={() => setLastPole(pole)}
                                                />
                                            } else {
                                                return <Marker 
                                                    position={{ lat: pole.latitude, lng: pole.longitude }} 
                                                    icon={{
                                                        url: 'https://easapi.enviroarborsolutions.com/svgs/utility_pole_red.svg',
                                                        scaledSize: { width: 32, height: 32 }
                                                    }}
                                                    onClick={() => setLastPole(pole)}
                                                />
                                            }
                                        } else { return null }
                                    } else if(packetPoleList.includes(pole._id)) { 
                                        return <Marker 
                                            position={{ lat: pole.latitude, lng: pole.longitude }} 
                                            icon={{
                                                url: 'https://easapi.enviroarborsolutions.com/svgs/utility_pole_purple.svg',
                                                scaledSize: { width: 32, height: 32 }
                                            }}
                                            onClick={() => { 
                                                setPacketPoleList( poles => {
                                                    let array = [ ...poles ];
                                                    let index = array.indexOf(pole._id);
                                                    array.splice(index, 1);
                                                    return array;
                                                });
                                                setLastPole(pole);
                                            }}
                                        />
                                    } else {
                                        if(pole.inspection_type && pole.inspection_type === 'FULL') {
                                            return <Marker 
                                                position={{ lat: pole.latitude, lng: pole.longitude }} 
                                                icon={{
                                                    url: 'https://easapi.enviroarborsolutions.com/svgs/utility_pole_blue.svg',
                                                    scaledSize: { width: 32, height: 32 }
                                                }}
                                                onClick={() => { setLastPole(pole); setPacketPoleList( poles => [ ...poles, pole._id ] ); }}
                                            />
                                        }
                                        else if(pole.inspection_type && pole.inspection_type === 'VISUAL') {
                                            return <Marker 
                                                position={{ lat: pole.latitude, lng: pole.longitude }} 
                                                icon={{
                                                    url: 'https://easapi.enviroarborsolutions.com/svgs/utility_pole_green.svg',
                                                    scaledSize: { width: 32, height: 32 }
                                                }}
                                                onClick={() => { setLastPole(pole); setPacketPoleList( poles => [ ...poles, pole._id ] ); }}
                                            />
                                        }
                                        else {
                                            return <Marker 
                                                position={{ lat: pole.latitude, lng: pole.longitude }} 
                                                icon={{
                                                    url: 'https://easapi.enviroarborsolutions.com/svgs/utility_pole_orange.svg',
                                                    scaledSize: { width: 32, height: 32 }
                                                }}
                                                onClick={() => { setLastPole(pole); setPacketPoleList( poles => [ ...poles, pole._id ] ); }}
                                            />
                                        }
                                    }
                                } else { return null }
                            }) : <></>)}
                        </GoogleMap>
                    ) : <></>}
                </div>
            </div>
        </Modal>
    )
}